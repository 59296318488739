import React, { useState, useEffect } from "react";
import { Chip } from "react-materialize";
import { subscribeToEvent, sendRequest } from "../api";
import RiddleCard from "./RiddleCard";
import OverrideStatus from "./OverrideStatus";

const RFIDCard = (props) => {
  const [updateTime, setUpdateTime] = useState(0);
  const [rfidStatus, setRfidStatus] = useState({
    rfid: new Array(props.numSensors).fill(""),
    solutions: new Array(props.numSensors).fill(""),
    chips: new Array(0),
    solved: false,
    override: false,
    status: "",
  });

  const setOverride = (value) => {
    if (!rfidStatus["override"]) {
      sendRequest("set_" + props.apiName + "_override");
    } else {
      sendRequest("clear_" + props.apiName + "_override");
    }
  };

  useEffect(() => {
    if (props.ws !== null) {
      props.ws.addEventListener("message", function (event) {
        const json = JSON.parse(event.data);
        if (props.apiName in json) {
          console.log(json[props.apiName]);
          setRfidStatus(json[props.apiName]);
          setUpdateTime(new Date());
        }
      });
    }
  }, [props.ws]);

  const falseClass = "white-text red lighten-2";
  const trueClass = "white-text green lighten-2";
  const getClass = (value, solution) => {
    if (value === solution) {
      return trueClass;
    }
    if (value !== "-") {
      return falseClass;
    }
    return "";
  };
  return (
    <RiddleCard title={props.name} updateTime={updateTime}>
      <div>
        <Chip
          className={
            rfidStatus["status"] === "Connected" ? trueClass : falseClass
          }
        >
          {rfidStatus["status"]}
        </Chip>
      </div>

      <div>
        {Array.from({ length: props.numSensors }, (item, index) => (
          <Chip
            className={getClass(
              rfidStatus.rfid[index],
              rfidStatus.solutions[index]
            )}
          >
            {index}
          </Chip>
        ))}
      </div>

      <OverrideStatus
        override={rfidStatus["override"]}
        solved={rfidStatus["solved"]}
        setOverride={setOverride}
      />
    </RiddleCard>
  );
};

export default RFIDCard;
