import React, { useState, useEffect } from "react";

import { Button, Chip } from "react-materialize";
import { subscribeToEvent, sendRequest } from "../api";
import RiddleCard from "./RiddleCard";

const GameControlCard = (props) => {
  const [updateTime, setUpdateTime] = useState(0);
  const [timerStatus, setTimerStatus] = useState({
    seconds_left: 0,
    is_running: false,
  });

  const pause = () => {
    props.ws.send(props.apiName + "_pause");
    console.log("pause");
    //setPaused(true);
  };

  const resume = () => {
    sendRequest("resume");
    props.ws.send(props.apiName + "_play");
    console.log("resume");
    //setPaused(false);
  };
  const intro = () => {
    sendRequest("intro");
    console.log("intro");
  };

  useEffect(() => {
    if (props.ws !== null) {
      props.ws.addEventListener("message", function (event) {
        const json = JSON.parse(event.data);
        if (props.apiName in json) {
          //console.log(json[props.apiName]);
          console.log(event.data);
          setTimerStatus(json[props.apiName]);
          setUpdateTime(new Date());
        }
      });
    }
  }, [props.ws]);

  const set_time = () => {
    const time_min = Math.ceil(timerStatus.seconds_left / 60);

    const sign = window.prompt("Set remaining time in min: (1-60)", time_min);

    console.log(sign);
    if (Number.isInteger(parseInt(sign)) && !Number.isNaN(parseInt(sign))) {
      const number = Math.ceil(sign * 60);
      if (number > 0 && number <= 60 * 60) {
        const time_cmd = "set_time_" + number;
        console.log(time_cmd);
        sendRequest(time_cmd);
      }
    }
  };
  console.log(props.data);
  const minutes = Math.floor(timerStatus.seconds_left / 60);
  const seconds = timerStatus.seconds_left - minutes * 60;
  const title =
    "Time left: " + minutes + ":" + String(seconds).padStart(2, "0");

  const reset = () => {
    sendRequest("reset");
    console.log("reset");
  };
  return (
    <RiddleCard title="" updateTime={updateTime}>
      <span>
        <Chip style={{ width: "150px" }}>{title}</Chip>
        <Button waves="light" className="red lighten-2" onClick={intro}>
          Restart
        </Button>
        <Button waves="teal" onClick={timerStatus.is_running ? pause : resume}>
          {timerStatus.is_running ? "Pause" : "Resume"}
        </Button>

        <Button waves="light" onClick={set_time} className="red lighten-2">
          Set time
        </Button>
        <Button waves="light" onClick={reset} className="red lighten-2">
          Reset
        </Button>
      </span>
    </RiddleCard>
  );
};

export default GameControlCard;
