import React, { useState, useEffect } from "react";
import { Card } from "react-materialize";

const RiddleCard = (props) => {
  const [commTimeout, setCommTimeout] = useState(false);

  useEffect(() => {
    setCommTimeout(false);
  }, [props.updateTime, setCommTimeout]);

  useEffect(() => {
    //console.log("Start timer");
    const timer = setTimeout(() => {
      setCommTimeout(true);
    }, 2000);
    return () => {
      //console.log("Clear");
      clearTimeout(timer);
    };
  }, [props.updateTime, setCommTimeout]);

  const classConnected = "blue-grey lighten-3 center";
  const classDisconnected = "black lighten-3 center";

  return (
    <Card
      className={commTimeout ? classDisconnected : classConnected}
      textClassName="white-text"
      title={props.title}
    >
      {props.children}
    </Card>
  );
};

export default RiddleCard;
