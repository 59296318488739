import openSocket from "socket.io-client";
const ENDPOINT = "http://127.0.0.1:4001";
//const ENDPOINT = "http://127.0.0.1:5000";
//const ENDPOINT = "http://raspberrypi.local:5000";
//const ENDPOINT = "http://raspberrypi.local:4001";

const socket = openSocket(ENDPOINT);
function subscribeToEvent(name, cb) {
  socket.on(name, (data) => {
    //console.log(name, data);
    if (typeof data === "string") {
      data = JSON.parse(data);
    }
    cb(null, data);
  });
}

function sendRequest(request) {
  socket.emit("gui_request", request);
}

export { subscribeToEvent, sendRequest };
