import React, { useState } from "react";

import { Button, Chip, Modal } from "react-materialize";

const OverrideStatus = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <Chip className={props.override ? "yellow" : ""}>
        Solved: {props.solved ? "Yes" : "No"}
      </Chip>
      <label>
        <input
          type="checkbox"
          checked={props.override}
          onChange={() => {
            if (!props.override) {
              setIsOpen(true);
            } else {
              props.setOverride(false);
            }
          }}
        />
        <span>Override</span>
      </label>

      <Modal
        header="Override"
        options={{ dismissible: false }}
        open={isOpen}
        actions={[
          <Button
            flat
            onClick={() => {
              setIsOpen(false);
              props.setOverride(true);
            }}
          >
            Yes
          </Button>,
          <Button
            flat
            onClick={() => {
              setIsOpen(false);
            }}
          >
            No
          </Button>,
        ]}
      >
        <p>Are you sure?</p>
      </Modal>
    </React.Fragment>
  );
};

export default OverrideStatus;
