import React, { useState, useEffect } from "react";
import { subscribeToEvent, sendRequest } from "../api";
import StatusChip from "./StatusChip";
import OverrideStatus from "./OverrideStatus";
import RiddleCard from "./RiddleCard";

const SwitchCard = (props) => {
  const [updateTime, setUpdateTime] = useState(0);
  const [switchStatus, setSwitchStatus] = useState({
    switches: new Array(props.numSwitches).fill(false),
    solved: false,
    override: false,
  });

  const setSwitchOverride = (value) => {
    if (!switchStatus["override"]) {
      sendRequest("set_" + props.apiName + "_override");
    } else {
      sendRequest("clear_" + props.apiName + "_override");
    }
  };

  useEffect(() => {
    subscribeToEvent("gui_" + props.apiName, (err, data) => {
      setSwitchStatus(data);
      setUpdateTime(new Date());
    });
  }, []);

  useEffect(() => {
    if (props.ws !== null) {
      props.ws.addEventListener("message", function (event) {
        const json = JSON.parse(event.data);
        if (props.apiName in json) {
          //console.log(json[props.apiName]);
          setSwitchStatus(json[props.apiName]);
          setUpdateTime(new Date());
        }
      });
    }
  }, [props.ws]);

  return (
    <RiddleCard title={props.name} updateTime={updateTime}>
      <div>
        {Array.from({ length: props.numSwitches }, (item, index) => (
          <StatusChip status={switchStatus.switches[index]}>
            {props.switchNames ? props.switchNames[index] : "Switch"}
          </StatusChip>
        ))}
      </div>

      <OverrideStatus
        override={switchStatus["override"]}
        solved={switchStatus["solved"]}
        setOverride={setSwitchOverride}
      />
    </RiddleCard>
  );
};

export default SwitchCard;
