import React, { useState, useEffect } from "react";

import GameControlCard from "./components/GameControlCard";
import RFIDCard from "./components/RFIDCard";
import MiscCard from "./components/MiscCard";
import MiscBoolCard from "./components/MiscBoolCard";

import SwitchCard from "./components/SwitchCard";

import { Row, Col } from "react-materialize";

//const ENDPOINT = "ws://127.0.0.1:8080";
const ENDPOINT = "ws://circusmain.local:8080";

function App() {
  const [data, setData] = useState({ data: 0, is_running: false });
  const [updateTime, setUpdateTime] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [ws, setWs] = useState(null);

  useEffect(() => {
    if (!isConnected) {
      const ws = new WebSocket(ENDPOINT);
      setWs(ws);
      ws.onopen = (event) => {
        //setIsConnected(true);
      };
      ws.onmessage = function (event) {
        try {
          const json = JSON.parse(event.data);
          setData(json);
          setUpdateTime(new Date());
        } catch (e) {
          console.log(event.data);
          console.log(e);
        }

        //console.log(json);
      };
      //clean up function
      return () => ws.close();
    }
  }, [isConnected]);

  return (
    <div className="App container">
      <GameControlCard ws={ws} apiName="timer_status" />
      <Row>
        <Col s={12} m={12} l={6}>
          <MiscCard
            ws={ws}
            name="DMX"
            numChips={3}
            apiName="dmx_status"
            chipNames={["Left", "Right", "UV"]}
          />
          <MiscBoolCard
            ws={ws}
            name="Relay"
            numChips={4}
            apiName="relay_status"
            chipNames={["Window", "Magician", "Stage", "Trap door"]}
          />

          <SwitchCard
            ws={ws}
            name="Clown nose"
            numSwitches={1}
            apiName="clown_nose_status"
          />

          <SwitchCard
            ws={ws}
            name="Magician hat"
            numSwitches={5}
            apiName="switch_magician"
          />

          <SwitchCard
            ws={ws}
            name="Chests"
            switchNames={[
              "Psychic",
              "Ticket Master",
              "Magician",
              "Sword Swallower",
              "Beast Tamer",
              "Juggler",
            ]}
            numSwitches={6}
            apiName="chest_status"
          />
        </Col>

        <Col s={12} m={12} l={6}>
          <RFIDCard
            ws={ws}
            name="Vanessa Poster (Room 1)"
            numSensors={1}
            apiName="vanessa_poster_status"
          />
          <RFIDCard
            ws={ws}
            name="Evidence"
            numSensors={6}
            apiName="evidence_status"
          />
          <RFIDCard
            ws={ws}
            name="Vanessa Picture Room 2"
            numSensors={1}
            apiName="vanessa_picture_status"
          />
          <RFIDCard
            ws={ws}
            name="Murderer Picture"
            numSensors={1}
            apiName="murderer_picture_status"
          />
        </Col>
      </Row>
    </div>
  );
}

export default App;
//        It's <time dateTime={response}>{response}</time>
// </div></div>        <button onClick={flash}>
//            Lightning
//        </button>
