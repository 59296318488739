import React from "react";
import { Chip } from "react-materialize";

const StatusChip = (props) => {
  const falseClass = "white-text red lighten-2";
  const trueClass = "white-text green lighten-2";

  return (
    <Chip className={props.status ? trueClass : falseClass}>
      {props.children}
    </Chip>
  );
};

export default StatusChip;
